.trt-article-page-wrapper {
  @apply sm:max-w-articleMd md:max-w-articleMd lg:max-w-articleLg xl:max-w-articleXl m-auto;
}

.trt-article-body-wrapper {
  @apply grid px-6 sm:px-0 grid-cols-1 
  sm:max-w-[506px] 
  md:max-w-[584px] 
  lg:max-w-full  
  lg:grid-cols-[1fr_648px_1fr] lg:max-w-articleLg 
  xl:grid-cols-[1fr_704px_1fr];
}

.article-hover-effect:hover {
  @apply opacity-70 transition-opacity duration-75 ease-linear;
}

.article-vertical-separator {
  @apply relative before:content-[""] before:absolute before:-end-3 before:h-full before:w-[0px] sm:before:w-[1px] lg:before:w-[0px] before:bg-grey-5;
}

.left-block__image-wrapper {
  @apply w-full grid grid-cols-1 gap-4
  sm:grid-cols-[1fr_minmax(auto,418px)] 
  md:grid-cols-[1fr_minmax(auto,508px)] 
  lg:grid-cols-[1fr_minmax(auto,480px)] lg:gap-6
  xl:grid-cols-[1fr_minmax(auto,612px)] xl:gap-8;    
}

.left-block__image-wrapper--reverse {
  @apply w-full grid grid-cols-1 gap-4
  sm:grid-cols-[minmax(auto,418px)_1fr] 
  md:grid-cols-[minmax(auto,508px)_1fr] 
  lg:grid-cols-[minmax(auto,480px)_1fr] lg:gap-6
  xl:grid-cols-[minmax(auto,612px)_1fr] xl:gap-8;
}
.left-block__image-wrapper--overal {
  @apply w-full grid grid-cols-1 
  lg:grid-cols-[minmax(auto,278px)_1fr] 
  lg2:grid-cols-[minmax(auto,400px)_1fr] 
  xl:grid-cols-[minmax(auto,346px)_1fr];
}

.vertical-image-child-content-wrapper {
  @apply w-full grid grid-cols-1 gap-4
  sm:grid-cols-[minmax(auto,210px)_1fr] 
  md:grid-cols-[minmax(auto,284px)_1fr] 
  lg:grid-cols-[minmax(auto,256px)_1fr] lg:gap-6
  xl:grid-cols-[minmax(auto,284px)_1fr] xl:gap-8;
}

.video-detail-global-wrapper {
  @apply w-full grid grid-cols-1 gap-[94px]
  sm:grid-cols-[minmax(auto,417px)_1fr] 
  md:grid-cols-[minmax(auto,584px)_1fr] 
  lg:grid-cols-[minmax(auto,786px)_1fr]
   xl:grid-cols-[minmax(auto,920px)_1fr];
}
